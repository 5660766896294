<template>
  <div class="content-accounts-receivable-all">
    <div class="content-txt-title-search" v-if="!bMobile && !bTablet">
      <p class="txt-title-accounts-receivable">Cuentas por cobrar</p>
      <div class="search-filter-accounts-receivable">
        <v-text-field
          v-model="sSearch"
          label="Buscar cliente"
          placeholder="Buscar cliente"
          class="global-text-field"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
        >
        </v-text-field>
      </div>
    </div>

    <div class="content-accounts-receivable-table-card-all">
      <!-- #region Contenedor de cuentas por cobrar (MXN) -->

      <!-- #region contenedor de titulo y filtro de cuentas por cobrar MXN -->
      <div class="content-txt-title-search" v-if="bMobile || bTablet">
        <p class="txt-title-accounts-receivable">Cuentas por cobrar MXN</p>
        <div class="search-filter-accounts-receivable">
          <v-text-field
            v-model="sSearch"
            label="Buscar cliente"
            placeholder="Buscar cliente"
            class="global-text-field"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </div>
      </div>
      <!-- #endregion contenedor de titulo y filtro de cuentas por cobrar MXN -->

      <div
        class="content-accounts-receivable-table content-accounts-receivable-table-mxn"
      >
        <!-- #region tabla de cuentas por cobrar (MXN) -->
        <report-accounts-receivable-table
          v-if="!bMobile"
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aTables="aAccountsReceivable"
          :numPages="numPages"
          :totalItems="totalItems"
          @getEmitAccountsReceivable="getEmitAccountsReceivable"
        />

        <!-- #endregion tabla de cuentas por cobrar (MXN) -->

        <!-- #region carta de cuentas por cobrar (MXN) -->
        <report-accounts-receivable-card
          v-else
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aCards="aAccountsReceivable"
          :numPages="numPages"
          :totalItems="totalItems"
          @getEmitAccountsReceivable="getEmitAccountsReceivable"
        />
        <!-- #endregion carta de cuentas por cobrar (MXN) -->

        <!-- #region Total de cuentas por cobrar (MXN) -->
        <div class="content-footer-total-accounts-receivable-all">
          <div class="content-footer-total-accounts-receivable">
            <div class="content-footer-txt-price-total-accounts-receivable">
              <div class="content-footer-txt-total-accounts-receivable">
                <p class="footer-txt-total-accounts-receivable">
                  TOTAL DE CUENTAS POR COBRAR
                </p>
              </div>
              <div class="content-footer-price-total-accounts-receivable">
                <p class="footer-price-total-accounts-receivable">
                  $ {{ formatMoneyGlobal(dTotalReceivable) }} MXN
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion Total de cuentas por cobrar (MXN) -->
      </div>
      <!-- #endregion Contenedor de cuentas por cobrar (MXN) -->

      <!-- #region Contenedor de cuentas por cobrar (USD) -->

      <!-- #region contenedor de titulo y filtro de cuentas por cobrar USD -->
      <div class="content-txt-title-search" v-if="bMobile || bTablet">
        <p class="txt-title-accounts-receivable">Cuentas por cobrar USD</p>
        <div class="search-filter-accounts-receivable">
          <v-text-field
            v-model="sSearch"
            label="Buscar cliente"
            placeholder="Buscar cliente"
            class="global-text-field"
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            persistent-placeholder
            outlined
          >
          </v-text-field>
        </div>
      </div>
      <!-- #endregion contenedor de titulo y filtro de cuentas por cobrar MXN -->

      <div
        class="content-accounts-receivable-table content-accounts-receivable-table-usd"
      >
        <!-- #region tabla de cuentas por cobrar (USD) -->
        <report-accounts-receivable-table
          v-if="!bMobile"
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aTables="aAccountsReceivableUSD"
          :numPages="numPagesUSD"
          :totalItems="totalItemsUSD"
          @getEmitAccountsReceivable="getEmitAccountsReceivableUSD"
        />
        <!-- #endregion tabla de cuentas por cobrar (USD) -->

        <!-- #region carta de cuentas por cobrar (USD) -->
        <report-accounts-receivable-card
          v-else
          @setOrder="setOrder"
          :aHeaders="aHeaders"
          :aCards="aAccountsReceivableUSD"
          :numPages="numPagesUSD"
          :totalItems="totalItemsUSD"
          @getEmitAccountsReceivable="getEmitAccountsReceivableUSD"
        />
        <!-- #endregion carta de cuentas por cobrar (USD) -->

        <!-- #region Total de cuentas por cobrar (USD) -->
        <div class="content-footer-total-accounts-receivable-all">
          <div class="content-footer-total-accounts-receivable">
            <div class="content-footer-txt-price-total-accounts-receivable">
              <div class="content-footer-txt-total-accounts-receivable">
                <p class="footer-txt-total-accounts-receivable">
                  TOTAL DE CUENTAS POR COBRAR
                </p>
              </div>
              <div class="content-footer-price-total-accounts-receivable">
                <p class="footer-price-total-accounts-receivable">
                  $ {{ formatMoneyGlobal(dTotalReceivableUSD) }} USD
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- #endregion Total de cuentas por cobrar (USD) -->
      </div>
      <!-- #endregion Contenedor de cuentas por cobrar (USD) -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    aAccountsReceivable: Array,
    aAccountsReceivableUSD: Array,
    dTotalReceivable: Number,
    dTotalReceivableUSD: Number,
    bMobile: Boolean,
    bTablet: Boolean,
    numPages: Number,
    numPagesUSD: Number,
    totalItems: Number,
    totalItemsUSD: Number,
  },
  data() {
    return {
      sSearch: "",
      aHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
          value: "sNumber",
          class: "global-header-table header-table-number-account-receivable  ",
          cellClass: "global-body-table body-table-number-account-receivable ",
        },
        {
          text: "Nombre del proveedor",
          sortable: false,
          value: "sName",
          class: "global-header-table ",
          cellClass: "global-body-table body-table-name-account-receivable",
        },
        {
          text: "Rango de tiempo",
          sortable: false,
          value: "sDateRange",
          class: "global-header-table ",
          cellClass:
            "global-body-table body-table-date-range-account-receivable",
        },
        {
          text: "Variación",
          sortable: false,
          value: "dVariation",
          align: "end",
          class: "global-header-table ",
          cellClass:
            "global-body-table body-table-variation-account-receivable",
        },
        {
          text: "Adeudo total",
          sortable: false,
          value: "dTotalDebit",
          align: "end",
          class: "global-header-table ",
          cellClass:
            "global-body-table body-table-payable-total-debit-account-receivable",
        },
      ],
    };
  },
  methods: {
    setOrder(oItem) {
      // 0a161001-b141-4039-8b8d-a5e7fd5b0b86
      this.$router.push({
        name: "OPDetailReport",
        params: { idOP: oItem.sOrderId },
      });
    },
    getEmitAccountsReceivable(obj) {
      this.$emit("getEmitAccountsReceivable", obj);
    },
    getEmitAccountsReceivableUSD(obj) {
      this.$emit("getEmitAccountsReceivableUSD", obj);
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearchAccountsReceivable", this.sSearch);
    },
  },
};
</script>
<style scoped>
.content-accounts-receivable-table-card-all {
  display: flex;
}
.content-accounts-receivable-table-mxn {
  width: 50% !important;

  padding-right: 5px;
}
.content-accounts-receivable-table-usd {
  width: 50% !important;

  padding-left: 5px;
}

.content-accounts-receivable-all {
  /* margin-top: 25px; */
  margin-bottom: 25px;
}
.content-txt-title-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt-title-accounts-receivable {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 18px;
}
.search-filter-accounts-receivable {
  width: 350px;
}

.content-footer-total-accounts-receivable-all {
  align-items: center;
  width: 100%;
  background-color: var(--primary-color-background-table) !important;
  padding: 5px 20px;
  min-height: 60px;
  max-height: 80px;
  border-radius: 15px;
}
.content-footer-total-accounts-receivable {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--primary-color-background-blue-transparent);
  min-height: 50px;
  border-radius: 10px;
  padding: 0px 20px;
}

.content-footer-txt-total-accounts-receivable {
  margin-right: 10px;
}
.content-footer-txt-price-total-accounts-receivable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer-txt-total-accounts-receivable,
.footer-price-total-accounts-receivable {
  font-family: "pop-Bold";
  font-size: 18px;
  color: var(--primary-color-text-white);
  margin-bottom: 0px;
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-accounts-receivable-all {
    /* margin-top: 25px; */
  }
  .content-txt-title-search {
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  .txt-title-accounts-receivable {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 18px;
  }
  .search-filter-accounts-receivable {
    width: 100%;
  }

  .content-accounts-receivable-table-card-all {
    display: block;
  }
  .content-accounts-receivable-table-mxn {
    width: 100% !important;
    margin-bottom: 25px;
    padding-right: 0px;
  }
  .content-accounts-receivable-table-usd {
    width: 100% !important;

    padding-left: 0px;
  }

  .content-footer-total-accounts-receivable-all {
    align-items: center;
    width: 100%;
    background-color: var(--primary-color-background-table) !important;
    padding: 5px 10px;
    min-height: 65px;
    max-height: 85px;
    border-radius: 15px;
    margin-top: 20px;
  }
  .content-footer-total-accounts-receivable {
    display: block;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--primary-color-background-blue-transparent);
    min-height: 50px;
    border-radius: 10px;
    padding: 5px 10px;
  }

  .content-footer-txt-total-accounts-receivable {
    margin-right: 0px;
  }
  .content-footer-txt-price-total-accounts-receivable {
    display: block;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .footer-txt-total-accounts-receivable,
  .footer-price-total-accounts-receivable {
    font-family: "pop-Bold";
    font-size: 15px;
    text-align: center;
    color: var(--primary-color-text-white);
    margin-bottom: 0px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .content-accounts-receivable-table-card-all {
    display: block;
  }
  .content-accounts-receivable-table-mxn {
    width: 100% !important;
    margin-bottom: 25px;
    padding-right: 0px;
  }
  .content-accounts-receivable-table-usd {
    width: 100% !important;

    padding-left: 0px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
  .content-footer-total-accounts-receivable-all {
    align-items: center;
    width: 100%;
    background-color: var(--primary-color-background-table) !important;
    padding: 5px 20px;
    min-height: 60px;
    max-height: 80px;
    border-radius: 15px;
  }
  .content-footer-total-accounts-receivable {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--primary-color-background-blue-transparent);
    min-height: 50px;
    border-radius: 10px;
    padding: 0px 20px;
  }

  .content-footer-txt-total-accounts-receivable {
    margin-right: 0px;
  }
  .content-footer-txt-price-total-accounts-receivable {
    display: block;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .footer-txt-total-accounts-receivable,
  .footer-price-total-accounts-receivable {
    font-family: "pop-Bold";
    text-align: right;
    font-size: 16px;
    color: var(--primary-color-text-white);
    margin-bottom: 0px;
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
